import React from 'react';
import PropTypes from 'prop-types';
import { userPropTypes } from 'helpers/proptypes';

import { eventWrapUpPropTypes } from './proptypes';
import AttendanceToggleTableRow from './AttendanceToggleTableRow';
import FacilitatorWrapUpTableBody from './FacilitatorWrapUpTableBody';

const FacilitatorWrapUp = (props) => {
  return (
    <div>
      <div className="table-responsive">
        <table className="table table-hover">
          <thead>
            {props.user.isAdmin ||
              (props.event.grades.length > 0 && !props.event.final && props.event.wrap_up && (
                <AttendanceToggleTableRow
                  attendedOn={props.attendedOn}
                  onToggleClick={props.onToggleClick}
                  isLoading={props.isLoading}
                />
              ))}
            <tr>
              <th>Name</th>
              <th className="center">Attendance</th>
              <th className="center">
                Participated
                <i
                  className="fa fa-info-circle icon-space-l"
                  data-tip="Completed course work and<br />participated to satisfaction."
                />
              </th>
              <th className="center">
                Evaluation
                <i
                  className="fa fa-info-circle icon-space-l"
                  data-tip="Will update as evaluations are completed."
                />
              </th>
            </tr>
          </thead>
          <FacilitatorWrapUpTableBody {...props} />
        </table>
      </div>
    </div>
  );
};

FacilitatorWrapUp.propTypes = {
  event: eventWrapUpPropTypes,
  user: userPropTypes,
  onToggleClick: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  attendedOn: PropTypes.bool.isRequired,
};

export default FacilitatorWrapUp;
