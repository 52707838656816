import React from 'react';
import PropTypes from 'prop-types';

const StaticGradeTableCell = ({ success }) => {
  return (
    <td className={success ? 'success center' : 'danger center'}>
      <span className={`fa ${success ? 'fa-check' : 'fa-minus'}`} />
    </td>
  );
};

StaticGradeTableCell.propTypes = {
  success: PropTypes.bool.isRequired,
};

export default StaticGradeTableCell;
