import React from 'react';
import PropTypes from 'prop-types';

const ToggleButton = ({ isLoading, isToggleOn, text, onClick }) => {
  if (isLoading) {
    return (
      <button disabled className="btn btn-primary">
        <i className="fa fa-spin fa-circle-o-notch icon-space-r" />
        {text}
      </button>
    );
  }
  return (
    <button onClick={onClick} className="btn btn-primary">
      <i
        className={isToggleOn ? 'fa fa-toggle-on icon-space-r' : 'fa fa-toggle-off icon-space-r'}
      />
      {text}
    </button>
  );
};

ToggleButton.displayName = 'ToggleButton';

ToggleButton.propTypes = {
  text: PropTypes.string.isRequired,
  isToggleOn: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

export default ToggleButton;
