import React from 'react';
import PropTypes from 'prop-types';
import { userPropTypes } from 'helpers/proptypes';
import { attendanceRecordPropType, gradePropType } from './proptypes';
import { AttendanceRecordDictionary } from 'helpers/dictionaries';
import ActiveGradeTableCell from './ActiveGradeTableCell';
import StaticGradeTableCell from './StaticGradeTableCell';

const { ATTENDANCE_STATUS } = AttendanceRecordDictionary;

class FacilitatorWrapUpTableRow extends React.Component {
  static propTypes = {
    grade: gradePropType,
    user: userPropTypes,
    attendance: PropTypes.arrayOf(attendanceRecordPropType),
    onFormSubmit: PropTypes.func.isRequired,
    final: PropTypes.bool.isRequired,
    isLoading: PropTypes.bool.isRequired,
    wrapUp: PropTypes.bool.isRequired,
  };

  handleAttendedClick = (grade) => {
    this.props.onFormSubmit({
      pk: grade.pk,
      attended: grade.checked,
    });
  };

  render() {
    const { grade, attendance, final, wrapUp, isLoading, user } = this.props;
    let attendancePercent = 0;
    if (attendance && attendance.length > 0) {
      let present = attendance.filter((record) =>
        [ATTENDANCE_STATUS.PRESENT, ATTENDANCE_STATUS.LATE].includes(record.status)
      );
      attendancePercent = Math.round((present.length / attendance.length) * 100);
    }
    const apClassNames =
      attendancePercent >= 90 ? 'success' : attendancePercent >= 80 ? 'warning' : 'danger';
    return (
      <tr>
        <td>{grade.attendance_name}</td>
        <td className={`${apClassNames} center`}>{attendancePercent}%</td>
        {user.isAdmin || (wrapUp && !final) ? (
          <ActiveGradeTableCell
            grade={grade}
            checked={grade.attended}
            onClick={this.handleAttendedClick}
            isLoading={isLoading}
          />
        ) : (
          <StaticGradeTableCell success={grade.attended} />
        )}
        <StaticGradeTableCell success={grade.evaluated} />
      </tr>
    );
  }
}

export default FacilitatorWrapUpTableRow;
