import PropTypes from 'prop-types';
import { AttendanceRecordDictionary, EventDictionary } from 'helpers/dictionaries';

export const eventStrandPropType = PropTypes.oneOf(Object.values(EventDictionary.STRAND_TYPES));
export const seasonPropType = PropTypes.oneOf(Object.values(EventDictionary.SEASON_TYPES));

export const attendanceRecordPropType = PropTypes.shape({
  pk: PropTypes.number.isRequired,
  attendee: PropTypes.number.isRequired,
  status: PropTypes.oneOf(Object.values(AttendanceRecordDictionary.ATTENDANCE_STATUS)),
});

export const gradePropType = PropTypes.shape({
  attended: PropTypes.bool.isRequired,
  evaluated: PropTypes.bool.isRequired,
  event: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  pk: PropTypes.number.isRequired,
  user: PropTypes.number.isRequired,
});

export const eventWrapUpPropTypes = PropTypes.shape({
  pk: PropTypes.number.isRequired,
  strand_type: eventStrandPropType.isRequired,
  title: PropTypes.string.isRequired,
  grades: PropTypes.arrayOf(gradePropType),
  attendance: PropTypes.object,
  season: seasonPropType.isRequired,
  year: PropTypes.string.isRequired,
});

export const progressReportPropType = PropTypes.shape({
  pk: PropTypes.number.isRequired,
  completed: PropTypes.bool.isRequired,
  completed_on: PropTypes.string,
  survey: PropTypes.string,
  closing_remarks: PropTypes.string,
});
