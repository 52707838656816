import React from 'react';
import PropTypes from 'prop-types';
import ReactTooltip from 'react-tooltip';
import { connect } from 'react-redux';
import ReactRouterPropTypes from 'react-router-prop-types';

import { Alert, Loading, PageHeader } from 'components';
import { Events, notifyError, notifySuccess } from 'api';
import { requestEventsDetail } from 'store/actions/events/detail';
import { requestCurrentUser } from 'store/actions/accounts/user';
import { failedAssertion } from 'helpers/reporting';
import { userPropTypes } from 'helpers/proptypes';
import FacilitatorWrapUp from './FacilitatorWrapUp';
import { EditableProgressReport, ToggleableProgressReportForm } from './ProgressReport';

const sortGradesByName = (grades) => {
  function compare(a, b) {
    return a.attendance_name > b.attendance_name
      ? 1
      : a.attendance_name < b.attendance_name
      ? -1
      : 0;
  }

  if (grades.length > 0) {
    grades.sort(compare);
  }
  return grades;
};

const groupAttendanceLogsByUser = (attendancelogs) => {
  const records = attendancelogs.reduce((accum, log) => accum.concat(log.records), []);
  let attendance = {};
  records.forEach((record) => {
    if (attendance[record.attendee]) {
      attendance[record.attendee].push(record);
    } else {
      attendance[record.attendee] = [record];
    }
  });
  return attendance;
};

class FacilitatorWrapUpPage extends React.Component {
  static propTypes = {
    match: ReactRouterPropTypes.match.isRequired,
    requestEventsDetail: PropTypes.func.isRequired,
    requestCurrentUser: PropTypes.func.isRequired,
    user: userPropTypes,
  };

  state = {
    isLoading: true,
    hasErrored: false,
    event: null,
    toggleAttendanceLoading: false,
    attendedOn: false,
    user: null,
  };

  componentDidMount() {
    const { eventId } = this.props.match.params;
    this.props.requestCurrentUser();
    this.props.requestEventsDetail(eventId);
    Events.facilitatorWrapUpDetail(eventId)
      .then((event) => {
        event = { ...event, grades: sortGradesByName(event.grades) };
        event = { ...event, attendance: groupAttendanceLogsByUser(event.attendancelogs) };
        this.setState({ isLoading: false, event });
      })
      .catch((err) => {
        this.setState({ hasErrored: true });
        failedAssertion(err);
      });
  }

  handleStartProgressClick = () => {
    const { event } = this.state;
    Events.initializeGrades(event.pk)
      .then((grades) => {
        const updatedEvent = { ...event, grades };
        this.setState({ isLoading: false, event: updatedEvent });
      })
      .catch((error) => notifyError(error));
  };

  handleSetActiveItem = (index) => {
    this.setState({ activeItemIndex: index });
  };

  handleAttendanceToggleClick = (isToggleOn) => {
    const { event } = this.state;
    const optimisticGrades = [];
    const gradesPromises = event.grades.map((grade) => {
      if (grade.attended !== isToggleOn) {
        optimisticGrades.push({ ...grade, attended: isToggleOn });
        return Events.patchGrade(event.pk, grade.pk, {
          attended: isToggleOn,
        }).catch((error) => {
          notifyError(
            error,
            `Failed to toggle ${grade.attendance_name} ${isToggleOn ? 'on' : 'off'}`
          );
          return Promise.resolve(grade);
        });
      }
      optimisticGrades.push(grade);
      return Promise.resolve(grade);
    });
    this.setState({ event: { ...event, grades: optimisticGrades }, toggleAttendanceLoading: true });
    Promise.all(gradesPromises)
      .then((grades) => {
        this.setState((prevState) => ({
          event: { ...event, grades },
          toggleAttendanceLoading: false,
          attendedOn: !prevState.attendedOn,
        }));
        notifySuccess(`Attendance records have been toggled ${isToggleOn ? 'on' : 'off'}.`);
      })
      .catch((error) => {
        this.setState({ event, toggleAttendanceLoading: false });
        failedAssertion('Something went wrong with gradesPromises', { error });
      });
  };

  handleFormSubmit = (grade) => {
    const { event } = this.state;
    const payload = { attended: grade.attended };

    Events.patchGrade(event.pk, grade.pk, payload)
      .then((res) => {
        const updatedEvent = {
          ...event,
          grades: event.grades.map((grade) => {
            if (grade.pk === res.pk) {
              return { ...grade, attended: res.attended };
            }
            return grade;
          }),
        };
        this.setState({ event: updatedEvent });
      })
      .catch((error) => notifyError(error));
  };

  handleProgressReportFormSubmit = (report) => {
    const { event } = this.state;
    const payload = {
      event: report.pk,
      closing_remarks: report.closing_remarks,
      completed: report.completed,
    };
    Events[`${report.method.toLowerCase()}ProgressReport`](report.pk, payload)
      .then((res) => {
        const updatedEvent = { ...event, progress_report: res };
        this.setState({ event: updatedEvent });
      })
      .catch((error) => notifyError(error));
  };

  render() {
    const { isLoading, hasErrored, event } = this.state;
    if (hasErrored) {
      return (
        <div className="col-xs-12">
          <Alert type="danger" icon="fa-exclamation-triangle">
            An error occurred.
          </Alert>
        </div>
      );
    }

    if (isLoading || !this.props.user) {
      return (
        <div className="col-xs-12">
          <Loading />
        </div>
      );
    }

    const report = event.progress_report;

    return (
      <div className="row">
        <div className="col-xs-12">
          <div
            className="white-soft-box2 white-soft-box2--no-animate"
            style={{ paddingBottom: '16px' }}
          >
            <PageHeader.Basic title="Event Wrap Up" subtitle={event.title} />
            <div className="mt1" />
            {!this.props.user.isAdmin &&
              !event.wrap_up &&
              (event.final ? (
                <Alert type="warning" icon="fa-info-circle">
                  Marks for this event are <strong>final</strong> and can no longer be changed.
                  Please reach out to a PD coordinator if you have questions.
                </Alert>
              ) : (
                <Alert type="info" icon="fa-info-circle">
                  Wrap up can be completed after this event ends.
                </Alert>
              ))}
            <FacilitatorWrapUp
              event={event}
              user={this.props.user}
              onFormSubmit={this.handleFormSubmit}
              onToggleClick={this.handleAttendanceToggleClick}
              onStartProgressClick={this.handleStartProgressClick}
              isLoading={this.state.toggleAttendanceLoading}
              attendedOn={this.state.attendedOn}
            />
            {!event.final && (
              <React.Fragment>
                <div className="page-divider" />
                <div className="row">
                  <div className="col-md-8 col-md-offset-2 col-lg-6 col-lg-offset-3">
                    {report ? (
                      <EditableProgressReport
                        eventPk={event.pk}
                        report={report}
                        onFormSubmit={this.handleProgressReportFormSubmit}
                      />
                    ) : (
                      <ToggleableProgressReportForm
                        eventPk={event.pk}
                        onFormSubmit={this.handleProgressReportFormSubmit}
                      />
                    )}
                  </div>
                </div>
              </React.Fragment>
            )}
          </div>
          <ReactTooltip
            place="right"
            type="custom"
            effect="solid"
            className="events__tooltip"
            multiline
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ accountsUser }) => {
  return {
    user: accountsUser[accountsUser.current],
  };
};

export default connect(
  mapStateToProps,
  { requestEventsDetail, requestCurrentUser }
)(FacilitatorWrapUpPage);
