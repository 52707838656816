import React from 'react';
import PropTypes from 'prop-types';
import ToggleButton from './ToggleButton';

class AttendanceToggleTableRow extends React.Component {
  static propTypes = {
    attendedOn: PropTypes.bool.isRequired,
    onToggleClick: PropTypes.func.isRequired,
    isLoading: PropTypes.bool,
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.isLoading !== prevState.isLoading) {
      return { isLoading: nextProps.isLoading };
    }
    return null;
  }

  state = {
    isLoading: false,
  };

  handleClick = () => {
    this.setState({ isLoading: true });
    this.props.onToggleClick(!this.props.attendedOn);
  };

  render() {
    return (
      <tr>
        <td />
        <td />
        <td className="center">
          <ToggleButton
            onClick={this.handleClick}
            isToggleOn={this.props.attendedOn}
            text="Toggle Attended"
            isLoading={this.state.isLoading}
          />
        </td>
        <td />
      </tr>
    );
  }
}

export default AttendanceToggleTableRow;
