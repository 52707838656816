import React from 'react';
import PropTypes from 'prop-types';
import { gradePropType } from './proptypes';

class ActiveGradeTableCell extends React.Component {
  static propTypes = {
    grade: gradePropType,
    onClick: PropTypes.func.isRequired,
    checked: PropTypes.bool,
    isLoading: PropTypes.bool.isRequired,
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.checked !== prevState.checked) {
      return { checked: nextProps.checked };
    }
    return null;
  }

  state = {
    checked: this.props.checked || false,
  };

  handleClick = (e) => {
    const updatedValue = !this.state.checked;
    this.setState({ checked: updatedValue });
    this.props.onClick({
      pk: this.props.grade.pk,
      checked: updatedValue,
    });
  };

  render() {
    const { checked } = this.state;
    const tdClasses = checked ? 'success center' : 'danger center';
    return (
      <td className={tdClasses}>
        <input
          type="checkbox"
          checked={checked}
          onChange={this.handleClick}
          className="cursor-pointer"
          disabled={this.props.isLoading}
        />
      </td>
    );
  }
}

export default ActiveGradeTableCell;
