import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { progressReportPropType } from './proptypes';

export class EditableProgressReport extends React.Component {
  static propTypes = {
    eventPk: PropTypes.number.isRequired,
    report: progressReportPropType.isRequired,
    onFormSubmit: PropTypes.func.isRequired,
  };

  state = {
    editFormOpen: false,
  };

  handleEditClick = () => {
    this.openForm();
  };

  handleFormClose = () => {
    this.closeForm();
  };

  handleSubmit = (report) => {
    report = Object.assign({}, report, { method: 'PATCH' });
    this.props.onFormSubmit(report);
    this.closeForm();
  };

  closeForm = () => {
    this.setState({ editFormOpen: false });
  };

  openForm = () => {
    this.setState({ editFormOpen: true });
  };

  render() {
    if (this.state.editFormOpen) {
      return (
        <ProgressReportForm
          eventPk={this.props.eventPk}
          report={this.props.report}
          onFormSubmit={this.handleSubmit}
          onFormClose={this.handleFormClose}
        />
      );
    }
    const { report } = this.props;
    const className = classNames('label', 'icon-space-l icon-space-r', {
      'label-danger': !report.completed,
      'label-success': report.completed,
    });

    return (
      <div className="row mt3">
        <div className="col-xs-10 col-xs-offset-1 col-md-8 col-md-offset-2 col-lg-6 col-lg-offset-3">
          <h3 className="center">
            Progress Report
            <button
              type="button"
              className="btn btn-sm btn-warning pull-right"
              onClick={this.handleEditClick}
            >
              <i className="fa fa-pencil" onClick={this.handleEditClick} />
            </button>
          </h3>
          <p>
            <strong>Status -</strong>
            <span className={className}>{report.completed ? 'Complete' : 'Incomplete'}</span>
            {report.completed ? report.completed_on : ''}
          </p>
          <p>
            <strong>Closing Remarks:</strong>
          </p>
          <p>{report.closing_remarks || 'No closing remarks.'}</p>
          <p className="mt3 diminished">
            Last modified by {report.editor ? report.editor : report.author}
          </p>
        </div>
      </div>
    );
  }
}

export class ToggleableProgressReportForm extends React.Component {
  static propTypes = {
    eventPk: PropTypes.number.isRequired,
    onFormSubmit: PropTypes.func.isRequired,
  };

  state = {
    isOpen: false,
  };

  handleFormOpen = () => {
    this.setState({ isOpen: true });
  };

  handleFormClose = () => {
    this.setState({ isOpen: false });
  };

  handleFormSubmit = (report) => {
    report = Object.assign({}, report, { method: 'POST' });
    this.props.onFormSubmit(report);
    this.setState({ isOpen: false });
  };

  render() {
    if (this.state.isOpen) {
      return (
        <ProgressReportForm
          eventPk={this.props.eventPk}
          onFormSubmit={this.handleFormSubmit}
          onFormClose={this.handleFormClose}
        />
      );
    } else {
      return (
        <div className="center mt3">
          <button className="btn btn-info" onClick={this.handleFormOpen}>
            <i className="fa fa-plus icon-space-l icon-space-r" onClick={this.handleFormOpen} />
            Complete Progress Report
          </button>
        </div>
      );
    }
  }
}

export class ProgressReportForm extends React.Component {
  static propTypes = {
    eventPk: PropTypes.number.isRequired,
    report: progressReportPropType,
    onFormClose: PropTypes.func.isRequired,
    onFormSubmit: PropTypes.func.isRequired,
  };

  state = {
    fields: {
      completed: (this.props.report && this.props.report.completed) || false,
      closing_remarks: (this.props.report && this.props.report.closing_remarks) || '',
    },
  };

  handleCompletedChange = (e) => {
    const fields = this.state.fields;
    fields['completed'] = !this.state.fields.completed;
    this.setState({ fields });
  };

  onInputChange = (e) => {
    const fields = this.state.fields;
    fields[e.target.name] = e.target.value;
    this.setState({ fields });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.onFormSubmit({
      pk: this.props.eventPk,
      completed: this.state.fields.completed,
      closing_remarks: this.state.fields.closing_remarks,
    });
  };

  render() {
    return (
      <div className="mt3">
        <button className="btn btn-default pull-right" onClick={this.props.onFormClose}>
          <i className="fa fa-times" onClick={this.props.onFormClose} />
        </button>
        <form onSubmit={this.handleSubmit}>
          <p className="center text-size">
            <strong>Progress Report Form</strong>
          </p>
          <div className="form-group">
            <label htmlFor="remarks">Closing Remarks</label>
            <textarea
              name="closing_remarks"
              className="form-control"
              id="remarks"
              placeholder="Closing Remarks..."
              onChange={this.onInputChange}
              value={this.state.fields.closing_remarks}
            />
            <p className="help-block">
              Anything you learned from the course or from the survey responses. Important if you
              are receiving CEUs for facilitating.
            </p>
          </div>
          <div className="form-group">
            <div className="checkbox">
              <label htmlFor="completed">
                <input
                  id="completed"
                  type="checkbox"
                  checked={this.state.fields.completed}
                  onChange={this.handleCompletedChange}
                />
                Completed
              </label>
              <p className="help-block">
                Marking completed does not mean you won't be able to edit later, it just means we
                won't email or remind you about this anymore.
              </p>
            </div>
          </div>
          <button className="btn btn-primary" onClick={this.handleSubmit}>
            <i className="fa fa-check icon-space-r" onClick={this.handleSubmit} />
            Submit
          </button>
          &nbsp;
          <button className="btn btn-default" onClick={this.props.onFormClose}>
            <i className="fa fa-ban icon-space-r" onClick={this.props.onFormClose} />
            Cancel
          </button>
        </form>
      </div>
    );
  }
}
