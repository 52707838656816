import React from 'react';
import PropTypes from 'prop-types';
import { userPropTypes } from 'helpers/proptypes';
import { eventWrapUpPropTypes } from './proptypes';
import FacilitatorWrapUpTableRow from './FacilitatorWrapUpTableRow';

const FacilitatorWrapUpTableBody = ({
  user,
  event,
  onFormSubmit,
  onStartProgressClick,
  isLoading,
}) => {
  if (event.grades.length > 0) {
    return (
      <tbody>
        {event.grades.map((grade) => (
          <FacilitatorWrapUpTableRow
            key={grade.pk}
            grade={grade}
            user={user}
            attendance={event.attendance[grade.user]}
            onFormSubmit={onFormSubmit}
            final={event.final}
            wrapUp={event.wrap_up}
            isLoading={isLoading}
          />
        ))}
      </tbody>
    );
  }
  return (
    <tbody>
      <tr>
        <td colSpan="5">No attendance marks have been submitted.</td>
      </tr>
      {!event.final && event.wrap_up && (
        <tr>
          <td colSpan="5">
            <button className="btn btn-info" type="button" onClick={onStartProgressClick}>
              Start Progress Report
            </button>
          </td>
        </tr>
      )}
    </tbody>
  );
};

FacilitatorWrapUpTableBody.propTypes = {
  event: eventWrapUpPropTypes,
  user: userPropTypes,
  onFormSubmit: PropTypes.func.isRequired,
  onStartProgressClick: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

export default FacilitatorWrapUpTableBody;
